import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
  user: {},
  search: {
    order_ref: '',
    email: '',
    name: '',
    payment: '',
    status: '',
    promotion: '',
    campaign: '',
    transactionFromDate: '',
    transactionFromHr: '',
    transactionFromMn: '',
    transactionToDate: '',
    transactionToHr: '',
    transactionToMn: '',
    paidFromDate: '',
    paidFromHr: '',
    paidFromMn: '',
    paidToDate: '',
    paidToHr: '',
    paidToMn: '',
    current_page: 1,
  }
};

const changeState = (state = initialState, { type, ...rest }) => {

  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "set_user":
      return { ...state, ...rest };
    case "set_search_invoice":
      return { ...state, ...rest };
    case 'clear_all':
      return initialState;
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, changeState);

export default () => {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};

