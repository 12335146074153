import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
  cilSearch,
  cilGroup,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilArrowLeft,
  cilAsterisk,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCopy,
  cilCalendar,
  cilClipboard,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilEyedropper,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGlobeAlt,
  cilGrid,
  cilGraph,
  cilHome,
  cilInbox,
  cilMenu,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPen,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning,
  cilPlus,
  cilUserPlus,
  cilBellExclamation,
  cilVolumeHigh,
  cilBrush,
  cilBook,
  cilBriefcase,
  cilBrowser,
  cilDelete,
  cilImagePlus,
  cilAddressBook,
  cilOpentype,
  cilVerticalAlignTop,
  cilPenNib,
  cilAlarm,
  cilBarChart,
  cilLoopCircular,
  cilCheckAlt,
  cilChevronCircleDownAlt,
  cilChevronCircleUpAlt,
  cilFolder,
  cilFolderOpen,
  cilChartLine,
  cilColorBorder,
  cilDescription,
  cilSync,
  cilMinus,
} from "@coreui/icons";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilSearch,
    cilGroup,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilArrowLeft,
    cilAsterisk,
    cilBan,
    cilBasket,
    cilBell,
    cilBold,
    cilBookmark,
    cilCalculator,
    cilCopy,
    cilCalendar,
    cilClipboard,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCircle,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCreditCard,
    cilCursor,
    cilCursorMove,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilEyedropper,
    cilFile,
    cilFullscreen,
    cilFullscreenExit,
    cilGlobeAlt,
    cilGrid,
    cilGraph,
    cilMenu,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilInputPower,
    cilItalic,
    cilJustifyCenter,
    cilJustifyLeft,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilListRich,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMap,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPen,
    cilPencil,
    cilPeople,
    cilPhone,
    cilPrint,
    cilPuzzle,
    cilSave,
    cilScrubber,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilX,
    cilXCircle,
    cilWarning,
    cilPlus,
    cilUserPlus,
    cilBellExclamation,
    cilVolumeHigh,
    cilBrush,
    cilBook,
    cilBriefcase,
    cilBrowser,
    cilDelete,
    cilImagePlus,
    cilAddressBook,
    cilOpentype,
    cilVerticalAlignTop,
    cilPenNib,
    cilAlarm,
    cilBarChart,
    cilLoopCircular,
    cilCheckAlt,
    cilChevronCircleDownAlt,
    cilChevronCircleUpAlt,
    cilFolder,
    cilFolderOpen,
    cilChartLine,
    cilColorBorder,
    cilDescription,
    cilSync,
    cilMinus,
  }
);
