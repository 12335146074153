import axios from 'axios';
import { WEB_API } from '../env';
axios.defaults.baseURL = WEB_API;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // window.location.href = 'http://dopa.aslsecurities.com/#/login';
      // window.location.href = 'http://122.155.0.74:7450/#/login';
      // window.location.href = 'http://buriram-shop-backoffice.makersoft.co.th/#/login';
      // window.location.href = 'http://localhost:8087/#/login';
      window.location.href = '/#/login';
    }
    throw error;
  }
);
